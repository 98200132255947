/* eslint-disable import/prefer-default-export */
import _fromPairs from 'lodash/fromPairs';
import _omit from 'lodash/omit';
import _toPairs from 'lodash/toPairs';
import * as Papa from 'papaparse';
import store from 'store2';
import { getGrade } from '@bdelab/roar-utils';

export class RoarScores {
  constructor() {
    this.tableURL = 'https://storage.googleapis.com/roar-sre/scores/sre_lookup_v3.csv';
    this.aiTableURL = 'https://storage.googleapis.com/roar-sre/scores/sre_parallel_equating_lookup.csv';
    this.lookupTable = [];
    this.aiLookupTable = []; // loading in the AI table — could be an easier way to do this?
    this.tableLoaded = false; // wanted to leave original tables in tact
    this.aiTableLoaded = false;
  }

  async initTable() {
    return new Promise((resolve, reject) => {
      const grade = store.session.get('config').userMetadata?.grade;

      if (!grade) reject();

      this.gradeForScore = getGrade(grade, 1, 12);

      Papa.parse(this.tableURL, {
        download: true,
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        step: (row) => {
          if (this.gradeForScore === Number(row.data.grade)) this.lookupTable.push(_omit(row.data, ['', 'X']));
        },
        complete: () => {
          this.tableLoaded = true;
          resolve();
        },
      });

      // parsing the lab-ai equating table
      Papa.parse(this.aiTableURL, {
        download: true,
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        step: (row) => {
          this.aiLookupTable.push(_omit(row.data, ['', 'X']));
        },
        complete: () => {
          this.aiTableLoaded = true;
          resolve();
        },
      });
    });
  }

  /**
   * This function calculates computed scores given raw scores for each subtask.
   *
   * The input raw scores are expected to conform to the following interface:
   *
   * interface IRawScores {
   *   [key: string]: {
   *     practice: ISummaryScores;
   *     test: ISummaryScores;
   *   };
   * }
   *
   * where the top-level keys correspond to this assessment's subtasks. If this
   * assessment has no subtasks, then there will be only one top-level key called
   * "composite." Each summary score object implements this interface:
   *
   * interface ISummaryScores {
   *   thetaEstimate: number | null;
   *   thetaSE: number | null;
   *   numAttempted: number;
   *   numCorrect: number;
   *   numIncorrect: number;
   * }
   *
   * The returned computed scores must have that same top-level keys as the
   * input raw scores, and each value must be an object with arbitrary computed
   * scores.  For example, one might return the SRE score (i.e., total correct
   * minus the total incorrect), a predicted TOSREC standard score, and a
   * predicted TOSREC percentile.
   *
   * {
   *   practice: {
   *    sreScore: w;
   *   },
   *   ai: {
   *     sreScore: x;
   *   },
   *   lab: {
   *     sreScore: y;
   *   },
   *   tosrec: {
   *     sreScore: z;
   *   },
   *   composite: {
   *     sreScore: y;
   *     tosrecSS: number;
   *     tosrecPercentile: number;
   *   }
   * }
   *
   * @param {*} rawScores
   * @returns {*} computedScores
   */

  computedScoreCallback = async (rawScores) => {
    const { taskId } = store.session.get('config');

    if (taskId !== 'sre') return null;
    // This returns an object with the same top-level keys as the input raw scores
    // But the values are the number of correct trials minus the number of
    // incorrect trials, not including practice trials.
    const computedScores = _fromPairs(
      _toPairs(rawScores).map(([subTask, subScore]) => {
        // For the "practice" subtask, we want to use the raw scores associated
        // with the "practice" assessment stage. For all others, we want to use the
        // "test" assessment stage.
        const scoringStage = subTask === 'practice' ? 'practice' : 'test';
        const numCorrect = subScore[scoringStage]?.numCorrect || 0;
        const numIncorrect = subScore[scoringStage]?.numIncorrect || 0;
        const sreScore = numCorrect - numIncorrect;
        return [subTask, { sreScore }];
      }),
    );

    // this function is to compute the composite score based on corpus (fitting a linear model)
    const computedScoreConversion = (score) => {
      if (score.lab?.sreScore) {
        return Math.max(score.lab.sreScore, 0);
      }
      // adjusting the ai scores to equate to the lab scores
      if (score.aiV1P1?.sreScore) {
        const rawScore = Math.max(score.aiV1P1.sreScore, 0);
        const aiRow = this.aiLookupTable.find((row) => row.rawScore === rawScore && row.form === 'aiP1');
        return aiRow.sreScore;
      }
      if (score.aiV1P2?.sreScore) {
        const rawScore = Math.max(score.aiV1P2.sreScore, 0);
        const aiRow = this.aiLookupTable.find((row) => row.rawScore === rawScore && row.form === 'aiP2');
        return aiRow.sreScore;
      }
      return 0;
    };

    // computedScores should now have keys for each corpus: lab, ai, and tosrec, aiV1P1, aiV2P2
    // But for the composite score, we just take the score for the lab corpus
    // We also clip the composite score to a minimum of 0.
    const compositeScore = computedScoreConversion(computedScores);

    computedScores.composite = {
      sreScore: compositeScore,
    };

    const configGrade = store.session.get('config').userMetadata?.grade;
    if (configGrade) {
      if (!this.tableLoaded) {
        await this.initTable();
      }

      // Then we find the row in the lookup table that corresponds to the composite score.
      const myRow = this.lookupTable.find((row) => row.sreScore === compositeScore);

      if (myRow !== undefined) {
        // And add columns in the lookup table except for the grade and sreScore.
        const { grade, sreScore, ...normedScores } = myRow;

        computedScores.composite = {
          sreScore: compositeScore,
          ...normedScores,
        };
      }
    }
    return computedScores;
  };
}
